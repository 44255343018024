import React, { useEffect, useState } from "react";
import Button from "../components/form/button/Button";
import { useForm } from "react-hook-form";
import { createOption } from "../utils/components";
import useGetAllStates from "../hooks/useGetAllStates";
import useCreateUpdateCustomerAddress from "../hooks/customer/useCreateUpdateCustomer";
import { navigate } from "gatsby";
import Layout from "../layout/Layout";
import AccountPageNavigation from "../components/account/AccountPageNavigation";
import { staticPage } from "../../config/staticPage";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import RepresentativeNavigation from "../components/account/RepresentativeNavigation";
import useGetRepresentativesDetails from "../hooks/representatives-details/useGetRepresentativesDetails";
import useGetCustomerAddressById from "../hooks/customer/ueGetCustomerAddressById";

const COUNTRY_OPTIONS = [createOption("US", "United States")];

function CreateUpdateAddressPage({ location }) {
  const queryParams = new URLSearchParams(location.search);
  const addressId = queryParams.get("address_id");
  const customerId = queryParams.get("customer_id");

  const { details: representative } = useGetRepresentativesDetails();
  const { createUpdateCustomerAddress } = useCreateUpdateCustomerAddress();
  const { STATE_OPTIONS } = useGetAllStates();
  const { address } = useGetCustomerAddressById(addressId, customerId);

  const fieladsArray = [
    { name: "Business Hours For Delivery" },
    { name: "Do You have a dock?" },
    { name: "Do you have forklift to unload merchandise from truck?" },
    { name: "Do you Require Lift Gate Service?" },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (address) {
      const updatedFormFields = fieladsArray.map((field) => {
        const matchingField = address?.form_fields?.find(
          (savedField) => savedField.name === field.name
        );

        return {
          name: field.name,
          value: matchingField ? matchingField.value : null,
        };
      });

      const initialValues = {
        first_name: address?.first_name || "",
        last_name: address?.last_name || "",
        company_name: address?.company || "",
        country: address?.country || "",
        zip_postcode: address?.postal_code || "",
        suburb_city: address?.city || "",
        state_province: address?.state_or_province || "",
        address_line_1: address?.address1 || "",
        address_line_2: address?.address2 || "",
        phone: address?.phone || "",
        business_hours: updatedFormFields[0]?.value || "",
        dock_type: updatedFormFields[1]?.value || "",
        forklift_type: updatedFormFields[2]?.value || "",
        lift_gate_service_type: updatedFormFields[3]?.value || "",
      };

      reset(initialValues);
    }
  }, [address, reset]);

  const onSubmit = async (values) => {
    const newObj = {
      first_name: values["first_name"],
      last_name: values["last_name"],
      company: values["company_name"],
      address1: values["address_line_1"],
      address2: values["address_line_2"],
      city: values["suburb_city"],
      state_or_province: values["state_province"],
      postal_code: values["zip_postcode"],
      country_code: values["country"],
      phone: values["phone"],
      form_fields: [
        {
          name: "Business Hours For Delivery",
          value: values["business_hours"],
        },
        {
          name: "Do You have a dock?",
          value: values["dock_type"],
        },
        {
          name: "Do you have forklift to unload merchandise from truck?",
          value: values["forklift_type"],
        },
        {
          name: "Do you Require Lift Gate Service?",
          value: values["lift_gate_service_type"],
        },
      ],
    };
    const res = await createUpdateCustomerAddress(
      newObj,
      Number(addressId) || ""
    );
    if (res?.status === 200) {
      navigate("/addresses");
    }
  };

  return (
    <>
      <div className="page-wrapper account-page-wrapper">
        <div className="container">
          <Breadcrumbs location={location} />
        </div>

        <div className="container">
          <div className="account-content">
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar">
                <div className="page-sidebar-inner">
                  <AccountPageNavigation path={"/addresses"} />
                  {representative && (
                    <RepresentativeNavigation representative={representative} />
                  )}{" "}
                </div>
              </div>
              <div className="col page-content">
                <div className="page-content-inner">
                  <div className="page-heading-section">
                    <h1 className="page-title">Addresses</h1>
                  </div>

                  <form
                    className="form form-mid"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-row form-row--half">
                      <div className="form-field">
                        <label className="form-label">
                          First Name
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="first_name"
                          type="text"
                          {...register("first_name", {
                            required: "First name is required",
                          })}
                          className={`form-input ${
                            errors.first_name ? "error" : ""
                          }`}
                          maxLength={50}
                        />
                        {errors.first_name && (
                          <div className="form-error-message">
                            {errors.first_name.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Last Name
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="last_name"
                          type="text"
                          {...register("last_name", {
                            required: "Last name is required",
                          })}
                          className={`form-input ${
                            errors.last_name ? "error" : ""
                          }`}
                          maxLength={50}
                        />
                        {errors.last_name && (
                          <div className="form-error-message">
                            {errors.last_name.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Business Name
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="company_name"
                          type="text"
                          {...register("company_name", {
                            required: "Business name is required",
                          })}
                          className={`form-input ${
                            errors.company_name ? "error" : ""
                          }`}
                          maxLength={50}
                        />
                        {errors.company_name && (
                          <div className="form-error-message">
                            {errors.company_name.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Country
                          <small>REQUIRED</small>
                        </label>
                        <select
                          id="country"
                          className={`form-select ${
                            errors.country ? "error" : ""
                          }`}
                          {...register("country", {
                            required: "Country is required",
                          })}
                        >
                          {COUNTRY_OPTIONS.map((option) => (
                            <option key={option.value} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.country && (
                          <div className="form-error-message">
                            {errors.country.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Zip/Postcode
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="zip_postcode"
                          type="text"
                          {...register("zip_postcode", {
                            required: "Zip/Postcode is required",
                          })}
                          className={`form-input ${
                            errors.zip_postcode ? "error" : ""
                          }`}
                          maxLength={6}
                        />
                        {errors.zip_postcode && (
                          <div className="form-error-message">
                            {errors.zip_postcode.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          State/Province
                          <small>REQUIRED</small>
                        </label>
                        <select
                          id="state_province"
                          {...register("state_province", {
                            required: "State/Province is required",
                          })}
                          className={`form-select ${
                            errors.state_province ? "error" : ""
                          }`}
                        >
                          {STATE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.state_province && (
                          <div className="form-error-message">
                            {errors.state_province.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Suburb/City
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="suburb_city"
                          type="text"
                          {...register("suburb_city", {
                            required: "Suburb/City is required",
                          })}
                          className={`form-input ${
                            errors.suburb_city ? "error" : ""
                          }`}
                          maxLength={50}
                        />
                        {errors.suburb_city && (
                          <div className="form-error-message">
                            {errors.suburb_city.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Business Address Line 1 (Street Address Only)
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="address_line_1"
                          type="text"
                          {...register("address_line_1", {
                            required: "Business Address Line 1 is required",
                          })}
                          className={`form-input ${
                            errors.address_line_1 ? "error" : ""
                          }`}
                          maxLength={50}
                        />
                        {errors.address_line_1 && (
                          <div className="form-error-message">
                            {errors.address_line_1.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Business Address Line 2 (STE APT ETC)
                        </label>
                        <input
                          id="address_line_2"
                          type="text"
                          {...register("address_line_2")}
                          className={`form-input`}
                          maxLength={50}
                        />
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Phone Number
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="phone"
                          type="number"
                          {...register("phone", {
                            required: "Phone number is required",
                            minLength: {
                              value: 10,
                              message:
                                "Phone number must be at least 10 digits long.",
                            },
                            maxLength: {
                              value: 14,
                              message:
                                "Phone number should not exceed 14 digits in length.",
                            },
                          })}
                          className={`form-input ${
                            errors.phone ? "error" : ""
                          }`}
                        />
                        {errors.phone && (
                          <div className="form-error-message">
                            {errors.phone.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label" htmlFor="business_hours">
                          Business Hours For Delivery
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="business_hours"
                          type="text"
                          {...register("business_hours", {
                            required: "Business hours for delivery is required",
                          })}
                          className={`form-input ${
                            errors.business_hours ? "error" : ""
                          }`}
                          maxLength={50}
                        />
                        {errors.business_hours && (
                          <div className="form-error-message">
                            {errors.business_hours.message}
                          </div>
                        )}
                      </div>

                      {/* Rest of the form fields with similar pattern */}
                    </div>
                    <div className="form-row form-row--half">
                      <div className="form-field">
                        <label className="form-label" htmlFor="dock_type">
                          Do You have a dock?
                        </label>
                        <select
                          id="dock_type"
                          className={`form-select `}
                          aria-label="Select example"
                          {...register("dock_type")}
                        >
                          <option value="">Pick one</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Not Applicable">Not Applicable</option>
                        </select>
                      </div>

                      <div className="form-field">
                        <label className="form-label" htmlFor="forklift_type">
                          Do you have forklift to unload merchandise from truck?
                        </label>
                        <select
                          id="forklift_type"
                          className={`form-select`}
                          aria-label="Select example"
                          {...register("forklift_type")}
                        >
                          <option value="">Pick one</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Not Applicable">Not Applicable</option>
                        </select>
                      </div>

                      <div className="form-field">
                        <label
                          className="form-label"
                          htmlFor="lift_gate_service_type"
                        >
                          Do you Require Lift Gate Service?
                        </label>
                        <select
                          id="lift_gate_service_type"
                          className={`form-select`}
                          aria-label="Select example"
                          {...register("lift_gate_service_type")}
                        >
                          <option value="">Pick one</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                          <option value="Not Applicable">Not Applicable</option>
                        </select>
                      </div>
                    </div>

                    <div className="form-actions text-center">
                      <Button type="submit" className="button">
                        SAVE ADDRESS
                      </Button>

                      <Button
                        onClick={() => {
                          navigate("/addresses");
                        }}
                        className="button button--secondary"
                      >
                        CANCEL
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "create-update-address") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

CreateUpdateAddressPage.Layout = Layout;
export default CreateUpdateAddressPage;
