import useSWR from "swr";
import Api from "../../services/Api";

export default function useGetCustomerAddressById(address_id, customer_id) {
  const getKey = () =>
    address_id ? `/customer/${customer_id}/address/${address_id}` : null;

  const fetcher = async (url) => {
    const response = await Api.get(url);
    return response?.data;
  };

  const { data: address, error, mutate } = useSWR(getKey(), fetcher);

  return {
    address,
    error,
    mutate,
  };
}
