import useSWR from 'swr'
import Api from '../services/Api'
import { createOption } from '../utils/components'
import { bcApiURL } from '../ApiEndpoints'

function useGetAllStates() {
  const STATE_OPTIONS = []
  const getKey = () => {
    return `${bcApiURL}?type=states`
  }

  const { data, error } = useSWR(() => getKey(), getStates)

  const isLoading = !data && !error

  if (data?.length) {
    data.forEach((state) =>
      STATE_OPTIONS.push(createOption(state.state, state.state))
    )
  }

  return { STATE_OPTIONS, isLoading }
}

async function getStates(url) {
  const country_id = 226 // Only US states.
  const bcEnpoint = `v2/countries/${country_id}/states`
  const response = await Api.post(url, {
    url: bcEnpoint,
    query_params: {},
    method: 'get',
    body: {},
  })
  return response.data
}

export default useGetAllStates
