import useSWR from 'swr';
import { customerPocURL } from '../../ApiEndpoints';
import API from '../../services/Api';

const useGetRepresentativesDetails = () => {
    const getKey = () => {
        return `${customerPocURL}`;
      };
      const { data } = useSWR(() => getKey(), getRepresentativesDetails);
      return {
        details: data,
      };
}

async function getRepresentativesDetails(url) {
    const response = await API.get(url)
    return response.data;
}

export default useGetRepresentativesDetails