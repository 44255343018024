import React, { Component } from 'react'

export default class IconMail extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="848"
        height="1024"
        viewBox="0 0 848 1024"
      >
        <title></title>
        <g id="icomoon-ignore"></g>
        <path d="M715.84 208h-583.68c-68.611 0.182-124.16 55.844-124.16 124.48 0 0 0 0 0 0v0 359.040c0 0 0 0 0 0 0 68.636 55.549 124.298 124.143 124.48h583.697c68.611-0.182 124.16-55.844 124.16-124.48 0 0 0 0 0 0v0-359.040c0 0 0 0 0 0 0-68.636-55.549-124.298-124.143-124.48h-0.017zM715.84 272h5.12l-296.96 216.32-296.96-216.32h5.12zM776 691.52c0 0 0 0.001 0 0.001 0 33.29-26.896 60.297-60.143 60.479h-583.697c-33.264-0.182-60.16-27.19-60.16-60.479 0 0 0-0.001 0-0.001v0-359.040c0.080-6.779 1.245-13.259 3.329-19.312l-0.129 0.432 330.24 240.32c5.22 3.851 11.78 6.163 18.88 6.163s13.66-2.312 18.968-6.225l-0.088 0.062 329.92-240.32c1.955 5.621 3.12 12.101 3.2 18.843v0.037z"></path>
      </svg>
    )
  }
}
