import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useSelector } from "react-redux";

const AccountPageNavigation = ({ path }) => {
  const { currentLoginUser } = useSelector((state) => state.customer);
  const customerGroupIsList = [11, 48, 1, 28, 60, 91, 98, 45, 90];

  const [showBulkOrders, setShowBulkOrders] = useState(
    localStorage.getItem("showBulkOrders") === "true"
  );
  const [showPurchaseOrders, setShowPurchaseOrders] = useState(
    localStorage.getItem("showPurchaseOrders") === "true"
  );

  useEffect(() => {
    localStorage.setItem("showBulkOrders", showBulkOrders);
    localStorage.setItem("showPurchaseOrders", showPurchaseOrders);
  }, [showBulkOrders, showPurchaseOrders]);

  useEffect(() => {
    if (
      path === "/purchase-orders/pending" ||
      path === "/purchase-orders/pending/details" ||
      path === "/purchase-orders/completed/details" ||
      path === "/purchase-orders/completed"
    ) {
      setShowBulkOrders(true);
      setShowPurchaseOrders(true);
    } else if (
      path === "/available-products" ||
      path === "/preorder-products"
    ) {
      setShowBulkOrders(true);
    } else if (path === "/orders") {
      handleClose();
    }
  }, [path]);

  const handleClose = () => {
    setShowBulkOrders(false);
    setShowPurchaseOrders(false);
  };

  return (
    <div className="account-nav-section">
      <ul className="account-nav-list">
        <li
          className={path === "/orders" ? "active" : null}
          onClick={handleClose}
        >
          <Link to="/orders">Orders</Link>
        </li>
        {customerGroupIsList.includes(currentLoginUser?.customer_group_id) ? (
          <li
            className={
              path === "/purchase-orders/pending" ||
              path === "/purchase-orders/completed" ||
              path === "/available-products" ||
              path === "/preorder-products" ||
              path === "/purchase-orders/pending/details" ||
              path === "/purchase-orders/completed/details"
                ? "active"
                : null
            }
          >
            <span
              onClick={() => {
                setShowBulkOrders(!showBulkOrders);
                setShowPurchaseOrders(false);
              }}
            >
              <Link to="/available-products"> Bulk Orders</Link>
            </span>
            <ul className={showBulkOrders ? "show" : "hide"}>
              <li
                className={path === "/available-products" ? "active" : null}
                onClick={() => setShowPurchaseOrders(false)}
              >
                <Link to="/available-products">Available Products</Link>
              </li>
              <li
                className={path === "/preorder-products" ? "active" : null}
                onClick={() => setShowPurchaseOrders(false)}
              >
                <Link to="/preorder-products">Preorder Products</Link>
              </li>
              <li
                className={
                  path === "/purchase-orders/pending" ||
                  path === "/purchase-orders/completed/details" ||
                  path === "/purchase-orders/pending/details" ||
                  path === "/purchase-orders/completed"
                    ? "active"
                    : null
                }
              >
                <span
                  onClick={() => setShowPurchaseOrders(!showPurchaseOrders)}
                >
                  Purchase Orders
                </span>
                <ul className={showPurchaseOrders ? "show" : "hide"}>
                  <li
                    className={
                      path === "/purchase-orders/pending" ||
                      path === "/purchase-orders/pending/details"
                        ? "active"
                        : null
                    }
                  >
                    <Link to="/purchase-orders/pending">Pending</Link>
                  </li>
                  <li
                    className={
                      path === "/purchase-orders/completed" ||
                      path === "/purchase-orders/completed/details"
                        ? "active"
                        : null
                    }
                  >
                    <Link to="/purchase-orders/completed">Completed</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        ) : null}
        <li
          className={path === "/returns" ? "active" : null}
          onClick={handleClose}
        >
          <Link to="/returns">Returns</Link>
        </li>
        <li
          className={path === "/messages" ? "active" : null}
          onClick={handleClose}
        >
          <Link to="/messages">
            Messages <span>(0)</span>
          </Link>
        </li>
        <li
          className={
            path === "/addresses" || path === "/create-update-address"
              ? "active"
              : null
          }
          onClick={handleClose}
        >
          <Link to="/addresses">Addresses</Link>
        </li>
        {/* <li>
          <Link to="/">
            Wish Lists <span>(2)</span>
          </Link>
        </li> */}
        <li className={path === "/account-settings" ? "active" : null}>
          <Link to="/account-settings">Account Settings</Link>
        </li>
      </ul>
    </div>
  );
};

AccountPageNavigation.propTypes = {
  path: PropTypes.string,
};

export default AccountPageNavigation;
