export function createOption(value, label) {
  return {
    value,
    label,
  }
}

export function formatName(firstName, lastName) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`
  }
  if (firstName) {
    return firstName
  }
  if (lastName) {
    return lastName
  }
}

export const createQueryParam = (key, value) => `%26${key}=${value}`
