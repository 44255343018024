import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

function Button(
  { children, disabled, variant, to, size = "sm", ...props },
  ref
) {
  function selectVariant(variant) {
    switch (variant) {
      case "primary":
        return null;
      case "secondary":
        return "button--secondary";
      default:
        return null;
    }
  }

  function selectSize(size) {
    switch (size) {
      case "small":
        return "button-small";
      case "large":
        return "button-large";
      default:
        return null;
    }
  }

  const button = (
    <button
      disabled={disabled}
      className={`button ${selectVariant(variant)} ${selectSize(size)}`}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );

  if (to) {
    return <Link to={to}>{button}</Link>;
  }

  return button;
}

Button.prototype = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["sm", "md"]),
  to: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.displayName = "Button";

export default React.forwardRef(Button);
