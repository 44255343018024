import React, { useState } from "react";
import IconUser from "../../assets/icon_js/IconUser";
import IconMail from "../../assets/icon_js/IconMail";

const RepresentativeNavigation = ({ representative }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
      <div className="account-representative-nav">
        <div className={`account-sidebar-toggle show-mobile${isCollapsed ? "" : " active"}`}>
          <h5 className="account-sidebar-toggle-link" onClick={toggleCollapse}>
            Your Sales Representative
          </h5>
        </div>
        <div className={`order-user-info-container${isCollapsed ? " hide-sidebar" : " show-sidebar"}`}>

          <>
            {representative?.name && (
              <div className="rep-info-item">
                <h4 className="representative-info-headig">
                  Your Sales Representative
                </h4>
                <p className="representative-info">
                  <i className="icon">
                    <IconUser />
                  </i>
                  {representative?.name}
                </p>
              </div>
            )}

            {representative?.email && (
              <div className="rep-info-item">
                <h4 className="representative-info-headig">Email</h4>
                <p className="representative-info">
                  <i className="icon">
                    <IconMail />
                  </i>
                  <a href={`mailto:${representative?.email}`}>
                    {representative?.email}
                  </a>
                </p>
              </div>
            )}

            {representative?.mobile && (
              <div className="rep-info-item">
                <h4 className="representative-info-headig">Mobile</h4>
                <p className="representative-info">
                  <i className="icon">
                    <IconMail />
                  </i>
                  {representative.mobile}
                </p>
              </div>
            )}

            {representative?.phone && (
              <div className="rep-info-item">
                <h4 className="representative-info-headig">Phone</h4>
                <p className="representative-info">
                  <i className="icon">
                    <IconMail />
                  </i>
                  {representative.phone}
                </p>
              </div>
            )}
          </>

        </div>
      </div>

    </>
  );
};

export default RepresentativeNavigation;
