import { navigate } from "gatsby";
import Api from "../../services/Api";
import { getCustomerId, getUser } from "../../utils/auth";
import useToastify from "../ui/useToastify";

async function addAddress(url, data) {
  const response = await Api.post(url, data);
  return response.data.data;
}

async function updateAddress(url, data) {
  const response = await Api.put(url, data);
  return response.data.data;
}

function useCreateUpdateCustomerAddress() {
  const { toastMessage } = useToastify();
  const customer = getCustomerId();
  const user = getUser();
  const createUpdateCustomerAddress = async (data, address_id) => {
    try {
      const fields = {
        ...(address_id && {
          id: address_id,
        }),
        first_name: data.first_name,
        last_name: data.last_name,
        ...(!address_id && {
          customer_id: customer,
        }),
        country_code: "US",
        company: data.company,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state_or_province: data.state_or_province,
        postal_code: data.postal_code,
        phone: data.phone,
        form_fields: data.form_fields
      };
      // Update Address.
      if (address_id) {
        const url = user?.customer_email ? `/customer/address` : null;
        await updateAddress(url, [fields]);
        navigate("/addresses");
        toastMessage("success", "Address updated successfully.");
      } else {
        // Add Address.
        const url = user?.customer_email
          ? `/customer/address?customer_id=${customer}`
          : null;
        await addAddress(url, [fields]);
        navigate("/addresses");
        toastMessage("success", "Address added successfully.");
      }
    } catch (e) {
      console.log(e);
    }
  };
  return { createUpdateCustomerAddress };
}

export default useCreateUpdateCustomerAddress;
